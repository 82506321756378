import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "src/components/core/layout/layout"
import Card from "src/components/core/card"
import styled from "styled-components"
import BookPreview from "src/components/books/book-preview"
import Spacer from "src/components/core/spacer"
import PostPreview from "src/components/posts/post-preview"

const LinkList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`
const SidebarText = styled.div`
    margin-top: 2rem;
    h3{
        margin-bottom: 0.5rem;
    }
`

const BooksSidebar = ({ sidebar, covers, toptags }) => {
  const data = useStaticQuery(graphql`
    query BooksSidebarQuery {
      allPrismicTag {
        nodes {
          id
          prismicId
          data {
            tag
          }
        }
      }
      allPrismicPost {
        nodes {
          uid
          data {
            title {
              text
            }
            tags {
              tag {
                document {
                  prismicId
                }
              }
            }
          }
        }
      }
    }
  `)
  console.log("datadata", data)
  // const books = data.allPrismicBook.nodes
  const posts = data.allPrismicPost.nodes
  const tags = data.allPrismicTag.nodes
  console.log(tags, posts)
  const corbuloTag = tags.find(tag => tag.data.tag.toLowerCase() === "corbulo's daughter")
  //   console.log('posts', posts.filter(post=>post.data.tags.some(tagEl=>tagEl.tag.document.prismicId === corbuloTagId)))

  const corbuloPosts = corbuloTag
    ? posts.filter(post =>
        post.data.tags.some(
          tagEl => tagEl.tag && tagEl.tag.document[0].prismicId === corbuloTag.prismicId
        )
      )
    : null
  console.log(corbuloPosts)
  const capraTag = tags.find(tag => tag.data.tag.toLowerCase() === "the prophet of pompeii")

  const capraPosts = capraTag
    ? posts.filter(post =>
        post.data.tags.some(
          tagEl => tagEl.tag && tagEl.tag.document[0].prismicId === capraTag.prismicId
        )
      )
    : null
  const frontinusTag = tags.find(
    tag => tag.data.tag.toLowerCase() === "gods of blood and water"
  )

  const frontinusPosts = frontinusTag
    ? posts.filter(post =>
        post.data.tags.some(
          tagEl => tagEl.tag && tagEl.tag.document[0].prismicId === frontinusTag.prismicId
        )
      )
    : null

  return (
    <SidebarText>
      Posts related to the books:
      {corbuloTag ? (
        <>
          <h3>Corbulo's Daughter:</h3>
          <LinkList>
            {corbuloPosts.map(post => (
              <li key={post.uid}>
                <a href={"/post/" + post.uid}>{post.data.title.text}</a>
              </li>
            ))}
          </LinkList>
        </>
      ) : null}
      {capraTag ? (
        <>
          <h3>The Prophet of Pompeii:</h3>
          <LinkList>
            {capraPosts.map(post => (
              <li key={post.uid}>
                <a href={"/post/" + post.uid}>{post.data.title.text}</a>
              </li>
            ))}
          </LinkList>
        </>
      ) : null}
      {frontinusTag ? (
        <>
          <h3>Gods of Blood and Water:</h3>

          <LinkList>
            {frontinusPosts.map(post => (
              <li key={post.uid}>
                <a href={"/post/" + post.uid}>{post.data.title.text}</a>
              </li>
            ))}
          </LinkList>
        </>
      ) : null}
    </SidebarText>
  )
}

export default BooksSidebar

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
const OuterWrap = styled.div`
    // font-size: 1.5rem;
    color: #474652;
    background-color: #FFFFFF;
    margin: 2rem 0 1rem 0;
    border-radius: 8px;
    padding: 1rem;
    `
const ToggleBtn = styled.span`

    display: block;
    cursor: pointer;
    &:hover{
        color: #D04C1A;
    }
    `

const StyledWrapper = styled.div`
    margin-left: -1rem;
    display: flex; 
    flex-wrap: wrap;
    // background-color: #FFFFFF;
    /* padding: 1.5rem; */
    /* overflow: hidden; */
    /* max-height: ${props=>props.open===true?"10rem" : 0}; */
    transition: all 0.5s;

`

const TagsWrapperStyle = styled.div`
    max-height: ${props=>props.open===true?"auto" : 0};
    max-width: ${props=>props.open===true?"auto" : 0};
    height:auto;
    transition: all 0.5s;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;
    a{
        margin: 0.2rem 1rem;
        @media screen and(min-width: 576px){
        margin: 1rem;
            
        }
    }
`

const TagsWrapper = ({children})=>{
    const [open, setOpen] = useState(false)
    const toggleOpen = ()=>{
        setOpen(!open)
    }
    useEffect(() => {
        setOpen(false)
      }, []);


    return (
        <OuterWrap>
        <StyledWrapper>
            <TagsWrapperStyle open={true}>
            {children.slice(0,4)}
            </TagsWrapperStyle>
            <TagsWrapperStyle open={open}>
            {children.slice(4,)}
            </TagsWrapperStyle>
        </StyledWrapper>
            <ToggleBtn  onClick={toggleOpen}>{open ? "less": "more"}</ToggleBtn>
        </OuterWrap>
    )
}

export default TagsWrapper
import React from 'react';
import styled from 'styled-components';
import shadow from "src/components/core/theme/shadows"

import {Link} from 'gatsby';

const StyledLink = styled(Link)`
        text-decoration: none;
        /* color: inherit; */
`
const StyledTag = styled.div`
    /* border-radius: 2px; */
    /* border: solid 1px #474652; */
    /* box-shadow: ${shadow(1)}; */
    /* padding: 0.2rem 0.7rem; */
    /* text-align: center; */
    /* margin: 1rem; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */

`

const Tag = ({tag, className})=>{
    return(
        <StyledLink to={"posts/?tag="+tag} className={className}>
            <StyledTag>{tag}</StyledTag>
        </StyledLink>
    )
}

export default Tag
import React from "react"
import styled from "styled-components"
import colors from "src/components/core/theme/colors"

const ButtonWrapper = styled.div`
  width: 100vw;
  border-radius: 8px 8px 0 0;
  position: fixed;
  bottom: 0;
  background-color: ${colors.white};
  z-index: 150;
  @media screen and (min-width: 576px){
    display: none;   
  } 
`

const ButtonContent = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Button = styled.div`
  border-radius: 8px;
  padding: 0.5rem 3rem;
  color: ${colors.white};
  font-weight: bold;
  background-color: ${colors.darkOrange};
`

const BuyNow = ({link}) => {
  return (
    <ButtonWrapper>
      <ButtonContent>
        On Amazon
        <a href={link}><Button>Buy now</Button></a>
      </ButtonContent>
    </ButtonWrapper>
  )
}

export default BuyNow

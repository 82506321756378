import React from "react"
import styled from "styled-components"
import shadow from "src/components/core/theme/shadows"
import colors from "src/components/core/theme/colors"

const BtnWrapper = styled.div`
    position: fixed;
    cursor: pointer;
    bottom: 4.5rem;
    z-index: 100;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: ${colors.lightOrange};
    box-shadow: ${shadow(1)};
    transition: all 0.1s;
    @media (min-width: 768px){
        display: none;
    }
    &:hover{
        box-shadow: ${shadow(16)};
    }
    &:after{
        content: "<";
        transform: ${(props)=>props.open ? 'rotate(180deg)' : 'rotate(0deg)'} ;
        transition: all 0.2s;
        height: 3rem;
        width: 3rem;
        line-height: 3rem;
        text-align: center;
        display: block;
        font-size: 1.5rem;
    }
    z-index: 2000;
`

const MobileToggle = ({open, onClick, toggled})=>{
    return (
        <BtnWrapper open={open} toggled={toggled} onClick={onClick}/>
    )
}

export default MobileToggle
import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Tag from 'src/components/tags/tag';
import TagsWrapper from 'src/components/tags/tags-wrapper';
import styled from 'styled-components';


const SlimTag = styled(Tag)`
font-size: 1.2rem;
    display: inline-block;
    margin: 0;
    &:not(:last-child){
        margin-right: 0.5rem;
    }
    &:hover{
        
    }
`

const TopTags = ()=>{
    const data = useStaticQuery(graphql`
    query TopTagsQuery {
        allPrismicTag {
          nodes {
            data {
              tag
            }
            id
          }
        }
        allPrismicPost {
          nodes {
            data {
              tags {
                tag {
                  id
                }
              }
            }
          }
        }
      }
      
  `)
  const tags = data.allPrismicTag.nodes.map(node=>node.data.tag)
  const countedTags = tags.reduce((allTags, tag) => { 
    if (tag in allTags) {
      allTags[tag]++;
    }
    else {
      allTags[tag] = 1;
    }
    return allTags;
  }, {});
  const tagList = Object.keys(countedTags).map(key=>{return{tag: key, occ: countedTags[key]}})
  const topTags = tagList.sort((a,b)=>(a.occ > b.occ) ? -1 : 1).slice(0,4);
  return(
      <div>
      <div>
          <p>Top topics:</p>
      </div>
          {topTags.map(tag=>
             <SlimTag key={tag.tag} tag={tag.tag}/>
          )}
      </div>
  )
}

export default TopTags
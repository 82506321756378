import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
        site:{

            siteMetadata: {
                defaultTitle,
                titleTemplate,
                defaultDescription,
                siteUrl,
                defaultImage,
                twitterUsername,
            },
        }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      }
      console.log(seo)
      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-157949612-1"></script>
                <script type="text/javascript">
                    {`var gtagId = 'UA-157949612-1';
window['ga-disable-' + gtagId] = true;
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());`}
                </script>
                <script src="https://cookiehub.net/cc/11614fc2.js"></script>
                <script type="text/javascript">
                    {`window.addEventListener("load", function() {
window.cookieconsent.initialise({
	onInitialise: function(status) {
		if (this.hasConsented('required')) {
		}
		if (this.hasConsented('analytics')) {
			window['ga-disable-UA-157949612-1'] = false;
			gtag('config', gtagId);
		}
		if (this.hasConsented('marketing')) {
		}
	},
	onAllow: function(category) {
		if (category == 'required') {
		}
		if (category == 'analytics') {
			window['ga-disable-UA-157949612-1'] = false;
			gtag('config', gtagId);
		}
		if (category == 'marketing') {
		}
	},
	onRevoke: function(category) {
		if (category == 'required') {
		}
		if (category == 'analytics') {
			window['ga-disable-UA-157949612-1'] = true;
		}
		if (category == 'marketing') {
		}
	}
})
});`}
                </script>
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        titleTemplate
        siteUrl
        twitterUsername
      }
    }
  }
`
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Avatar from "src/components/core/avatar"
// import Column from 'src/components/core/column';
import TopTags from "src/components/tags/toptags"
import { Container, Row, Col } from "react-grid-system"
import Card from "src/components/core/card"
import CardContent from "src/components/core/card-content"
import shadow from "src/components/core/theme/shadows"
import BooksSidebar from "src/components/books/books-sidebar"
import RelatedPosts from "src/components/posts/related-posts"
import handleViewport from "react-in-viewport"
import Image from "gatsby-image"

const StyledSidebar = styled.div`
transition: all 0.1s;
@media screen and (min-width: 576px){
position: sticky;
top: 2rem;
    
}
${({ open }) => {
  if (open) {
    return `position: fixed;
        top:0;  
        left:100vw;
        top:0;
        bottom:0;
            overflow:scroll;
        padding-top: 4rem;
        background-color: white;
        z-index: 100;
        padding-bottom: 4rem;
        width: 100vw;
        `
  }
}}
${({ open, bookLink }) => {
  if (open && bookLink) {
    return `
        padding-bottom: 4rem;
        `
  }
}}
${({ toggled }) => (toggled ? `left: 0;` : `left:100vw;`)}
@media screen and (max-width: 576px){
    
position: fixed;
    top: 2.5rem;
}
`

const BioWrapper = styled.div`
  /* text-align: center; */
`
const TopSpacer = styled.div`
  height: 2rem;
`

const SidebarText = styled.div`
  margin-top: 2rem;
  h3 {
    margin-bottom: 0.5rem;
  }
`
const StyledCard = styled(Card)`
  ${({ open }) => (open ? `box-shadow: none` : null)}
`

const CoverWrapper = styled.div`
  border-radius: 8px;
  box-shadow: ${shadow(8)};
  margin: 0 1rem;
  overflow: hidden;
`
const AmazonLink = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1rem;
`

const CoverPreview = styled.div``
const PreviewTitle = styled.h3``
const PreviewCoverWrap = styled.div``
const SidebarComp = ({
  bookLink,
  bookCover,
  sidebarimage,
  sidebar,
  covers,
  toptags,
  corbuloPosts,
  portrait,
  open,
  className,
  inViewport,
  enterCount,
  toggled,
  relatedPosts,
}) => {
  console.log(sidebarimage)
  const data = useStaticQuery(graphql`
    query SidebarQuery {
      prismicAbout {
        data {
          title {
            html
          }
          portrait {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          photo {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          bio {
            html
          }
          twitter {
            url
          }
          instagram {
            url
          }
          facebook {
            url
          }
        }
      }
      allPrismicTag {
        nodes {
          data {
            tag
          }
          prismicId
        }
      }
    }
  `)
  let text
  sidebar == true ? (text = data.prismicAbout.data.bio.html) : (text = sidebar)
  return (
    <StyledSidebar
      bookLink={bookLink}
      className={className}
      open={open}
      inViewport={inViewport}
      enterCount={enterCount}
      toggled={toggled}
      relatedPosts={relatedPosts}
    >
      <TopSpacer />
      <StyledCard open={open} className={className} elevation={1} sticky={true}>
        <CardContent>
          {bookCover ? (
            <>
              <CoverWrapper>
                <a href={bookLink}>
                  <Image fluid={bookCover} />
                </a>
              </CoverWrapper>
              {bookLink ? 
              <a href={bookLink}>
                <AmazonLink>Buy on Amazon</AmazonLink>
              </a>
              : null}
            </>
          ) : sidebarimage ? (
            <Avatar
              dS={"8rem"}
              dL={"14rem"}
              bp={"1400px"}
              photo={sidebarimage}
              margin="auto"
            />
          ) : (
            <Avatar
              dS={"8rem"}
              dL={"14rem"}
              bp={"1400px"}
              photo={
                portrait
                  ? data.prismicAbout.data.portrait.localFile.childImageSharp
                      .fluid
                  : data.prismicAbout.data.photo.localFile.childImageSharp.fluid
              }
              margin="auto"
            />
          )}

          <SidebarText>
            {sidebar == "booksSidebar" ? (
              <BooksSidebar corbuloPosts={corbuloPosts} />
            ) : (
              <BioWrapper dangerouslySetInnerHTML={{ __html: text }} />
            )}
          </SidebarText>
          {covers ? covers.map(cover => cover) : null}
          {toptags != false ? <TopTags /> : null}
          {relatedPosts != false ? <RelatedPosts posts={relatedPosts} /> : null}
        </CardContent>
      </StyledCard>
    </StyledSidebar>
  )
}

const Sidebar = handleViewport(SidebarComp, { rootMargin: "-1.0px" })

export default Sidebar

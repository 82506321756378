import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
// import Container from 'src/components/core/Container';
import theme from 'src/components/core/theme/theme';
import shadow from 'src/components/core/theme/shadows';

const StyledFooter = styled.footer`
    background-color: white;
    z-index: 1000;
    box-shadow: ${shadow(10)};
    padding: 0.2rem 0;
`

const StyledContainer= styled(Container)`
    padding-top: 0;
    padding-bottom: 0;
`

const StyledFooterText = styled.h3`
    font-family: "Cinzel";
    font-size: 1.4rem;
    font-weight: 500; 
    text-align: right;
    padding-right: 2rem; 
    margin: 0;  
`

const Footer = ()=>{

    return(
        <StyledFooter>
            <StyledFooterText>Anthony Jennings &copy;</StyledFooterText>
        </StyledFooter>
    )
}

export default Footer;
import React, { useState, useEffect } from "react"
import styled from "styled-components"
// import Container from "src/components/core/Container"
import { useStaticQuery, graphql } from "gatsby"
import Header from "src/components/core/layout/header/header"
import Footer from "src/components/core/layout/footer/footer"
import "src/components/core/layout/resetcss.css"
import Sidebar from "src/components/core/layout/sidebar/sidebar"
import { Container, Row, Col } from "react-grid-system"
import { setConfiguration } from "react-grid-system"
import colors from "src/components/core/theme/colors"
import MobileToggle from "src/components/core/layout/sidebar/mobiletoggle.jsx"
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock"
import BuyNow from 'src/components/mobileCTA/buynow';
import SEO from 'src/components/SEO'
import Helmet from 'react-helmet';
setConfiguration({ containerWidths: [640, 800, 960, 1300] })


const StyledLayout = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  @media screen and (max-width: 576px){
      
      padding-bottom: ${({ bookLink }) => bookLink ? "4rem" : 0};
    }
`

const StyledContainer = styled(Container)`
  background-color: ${colors.lightOrange};
  @media (max-width: 800px) {
    margin-bottom: 5rem;
  }
`

const StyledMain = styled.main`
  flex-grow: 1;
  min-height: calc(100vh - 95px);
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${colors.lightOrange};
`

const Spacer = styled.div`
  height: 3rem;
`
const Layout = ({
    bookLink,
    bookCover,
    children,
    sidebarimage,
    sidebar,
    covers,
    toptags,
    portrait,
    relatedPosts,
    title,
    description,
    seoImage
}) => {
    const data = useStaticQuery(graphql`
    query ThumbnailQuery {
        prismicThumbnail {
            data {
              website_thumbnail {
                sm {
                  url
                }
              }
            }
          }
        }
    `)
    const [open, setOpen] = useState(false)
    const [toggled, setToggled] = useState(false)
    const ev = () => {
        if (!open) {
            setOpen(!open)
            setTimeout(() => setToggled(!toggled), 10)
        } else {
            setToggled(!toggled)
            setTimeout(() => setOpen(!open), 200)
        }
        const sidebar = document.getElementById("sidebar")
        !open ? disableBodyScroll(sidebar) : clearAllBodyScrollLocks()
    }
    useEffect(() => {
        const sidebar = document.getElementById("sidebar")
    })
    return (
        <StyledLayout bookLink={bookLink}>
            <SEO 
                title={title}
                description={description}
                image={seoImage ? seoImage : data.prismicThumbnail.data.website_thumbnail.sm.url}
            />
            <Header />
            <StyledContainer>
                <Row justify="center">
                    <Col xs={12} md={9}>
                        <StyledMain>{children}</StyledMain>
                    </Col>
                    {sidebar ? (
                        <Col xs={12} md={3}>
                            <Sidebar
                                bookLink={bookLink}
                                id="targetElementId"
                                sidebarimage={sidebarimage}
                                bookCover={bookCover}
                                sidebar={sidebar}
                                covers={covers}
                                toptags={toptags}
                                portrait={portrait}
                                open={open}
                                id="sidebar"
                                toggled={toggled}
                                relatedPosts={relatedPosts}
                            />
                        </Col>
                    ) : null}
                </Row>
            </StyledContainer>
            {sidebar ?
                <MobileToggle onClick={ev} open={open} />
                : null}
            <Spacer/>
            <Footer />
            {bookLink ? <BuyNow link={bookLink} /> : null}
        </StyledLayout>
    )
}

export default Layout
